import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/HomeView.vue')
  },
  {
    path: '/home',
    name: 'home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/HomeView.vue')
  },
  {
    path: '/neworder',
    name: 'neworder',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "neworder" */ '../views/NewOrder.vue')
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  // {
  //   path: '/onesignal',
  //   name: 'onesignal',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "onesignal" */ '../views/OneSignalAllow.vue')
  // },
  {
    path: '/ts',
    name: 'ts',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ts" */ '../views/Township.vue')
  },
  {
    path: '/customer',
    name: 'customer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "customer" */ '../views/Customer.vue')
  },
  {
    path: '/company',
    name: 'company',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "company" */ '../views/Company.vue')
  },
  {
    path: '/ui',
    name: 'ui',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ui" */ '../views/UiAccept.vue')
  },
  {
    path: '/user',
    name: 'user',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "user" */ '../views/UserAccount.vue')
  },
  {
    path: '/useros',
    name: 'useros',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "useros" */ '../views/UserAccountOs.vue')
  },
  {
    path: '/branch',
    name: 'branch',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "branch" */ '../views/Branch.vue')
  },
  {
    path: '/payment',
    name: 'payment',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "payment" */ '../views/Payment.vue')
  },
  {
    path: '/income',
    name: 'income',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "income" */ '../views/Income.vue')
  },
  {
    path: '/expense',
    name: 'expense',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "expense" */ '../views/Expense.vue')
  },
  {
    path: '/finance',
    name: 'finance',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "finance" */ '../views/Finance.vue')
  },
  {
    path: '/riderrpt',
    name: 'riderrpt',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "riderrpt" */ '../views/ReportRider.vue')
  },
  {
    path: '/incomerpt',
    name: 'incomerpt',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "incomerpt" */ '../views/ReportIncome.vue')
  },
  {
    path: '/expenserpt',
    name: 'expenserpt',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "expenserpt" */ '../views/ReportExpense.vue')
  },
  {
    path: '/financerpt',
    name: 'financerpt',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "financerpt" */ '../views/ReportFinance.vue')
  },
  {
    path: '/bankrpt',
    name: 'bankrpt',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "bankrpt" */ '../views/ReportBank.vue')
  },
  {
    path: '/paymentrpt',
    name: 'paymentrpt',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "paymentrpt" */ '../views/ReportPayment.vue')
  },
  {
    path: '/debitcreditrpt',
    name: 'debitcreditrpt',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "debitcreditrpt" */ '../views/ReportDebitCredit.vue')
  },
  {
    path: '/osdebitrpt',
    name: 'osdebitrpt',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "osdebitrpt" */ '../views/ReportOSDebit.vue')
  },
  {
    path: '/profitlossrpt',
    name: 'profitlossrpt',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "profitlossrpt" */ '../views/ReportProfitLoss.vue')
  },
  {
    path: '/orderlist',
    name: 'orderlist',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "orderlist" */ '../views/OrderList.vue')
  },
  {
    path: '/riderlist',
    name: 'riderlist',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "riderlist" */ '../views/RiderList.vue')
  },
  {
    path: '/oslist',
    name: 'oslist',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "oslist" */ '../views/OSList.vue')
  },
  {
    path: '/toassign',
    name: 'toassign',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "toassign" */ '../views/ToAssign.vue')
  },
  {
    path: '/rtownship',
    name: 'rtownship',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rtownship" */ '../views/ReportTownShip.vue')
  },
  {
    path: '/toassignre',
    name: 'toassignre',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "toassignreturn" */ '../views/ToAssignReturn.vue')
  },
  {
    path: '/dailychecklist',
    name: 'dailychecklist',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dailychecklist" */ '../views/DailyCheckList.vue')
  },
  {
    path: '/trackinglist',
    name: 'trackinglist',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "trackinglist" */ '../views/TrackingList.vue')
  },
  {
    path: '/overduelist',
    name: 'overduelist',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "overduelist" */ '../views/OverdueList.vue')
  },
  {
    path: '/outinprint',
    name: 'outinprint',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "outinprint" */ '../views/PrintOutIn.vue')
  },
  {
    path: '/voucher',
    name: 'voucher',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "BarcodeGenerate" */ '../views/BarcodeGenerate.vue')
  },
  {
    path: '/voucheros',
    name: 'voucheros',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "VoucherGenerate" */ '../views/VoucherGenerate.vue')
  },
  {
    path: '/advance',
    name: 'advance',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "advancereport" */ '../views/ReportAdvance.vue')
  },
  {
    path: '/outin',
    name: 'outin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "outin" */ '../views/DailyOutIn.vue')
  },
  {
    path: '/orderlistdone',
    name: 'orderlistdone',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "orderlistdone" */ '../views/OrderListDone.vue')
  },
  {
    path: '/orderlistdonefinish',
    name: 'orderlistdonefinish',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "orderlistdonefinish" */ '../views/OrderListDoneFinish.vue')
  },
  {
    path: '/orderlistadvance',
    name: 'orderlistadvance',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "orderlistadvance" */ '../views/OrderListAdvance.vue')
  },
  {
    path: '/dev',
    name: 'dev',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "orderlistadvance" */ '../views/Developer.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
