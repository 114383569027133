import axios from "../config"

class UserAccountService{
    axios;
    constructor(){
        this.axios = axios;
    }
    changePassword(currentPassword, newPassword) {
      let url = `useraccounts/changepassword`
      return this.axios.get(url,
        {
          params: {
            currentPassword,
            newPassword
          }
        }).then(request => request.data);
    }
    getUserAccounts(userType) {
      let url = `/useraccounts`
      return axios.get(url,{
          params:{
              userType
          }
      }).then(request => request.data);
    }
    getUserAccountsProfile(userType,page,active) {
      let url = `/useraccounts/profile`
      return axios.get(url,{
          params:{
              userType,page,active
          }
      }).then(request => request.data);
    }
    getUserAccountsDetail(userAccountId) {
      let url = `/useraccounts/detail/${userAccountId}`
      return axios.get(url).then(request => request.data);
    }
    addUserAccounts(useraccounts) {
      let url = `/useraccounts`;
      return this.axios.post(url, useraccounts).then(request => request.data);
    }
    updateUserAccounts(user) {
      let url = `/useraccounts/${user.userAccountId}`;
      return this.axios.put(url, user).then(request => request.data);
    }
    deleteUser(user) {
      let url = `/useraccounts/${user.userAccountId}`;
      return this.axios.delete(url).then(request => request.data);
    }
    getUserTsJun(user) {
      let url = `/useraccounts/usertsjun/${user.userAccountId}`;
      return axios.get(url).then(request => request.data);
    }
    addUserTsJun(userts) {
      let url = `/useraccounts/usertsjun`;
      return this.axios.post(url, userts).then(request => request.data);
    }
    deleteUserTsJun(userts) {
      let url = `/useraccounts/usertsjun/${userts.userTsId}`;
      return this.axios.delete(url).then(request => request.data);
    }
    getUserAccountsExcludeOS() {
      let url = `/useraccounts/finance`
      return axios.get(url).then(request => request.data);
    }
    getUserAccountsExcludeOSDelivery() {
      let url = `/useraccounts/bankrpt`
      return axios.get(url).then(request => request.data);
    }
    getUserAccountsAccFinance() {
      let url = `/useraccounts/accfinance`
      return axios.get(url).then(request => request.data);
    }
  
  getUi(userType) {
    let url = `/ui`;
    return this.axios.get(url,{params:{
      userType
    }}).then(request => request.data);
  }
  updateUi(uiAccept) {
    let url = `/ui`;
    return this.axios.put(url,uiAccept).then(request => request.data);
  }
  getUiByUserId() {
    let url = `/ui/userId`;
    return this.axios.get(url).then(request => request.data);
  }
    getOnesignalAccount(){
      let url = `/onesignalAccount`;
      return this.axios.get(url).then(request => request.data);
    }
    getUserAccountSearch(searchText) {
      let url = `/useraccounts/search`
      return axios.get(url,{
          params:{
            searchText
          }
      }).then(request => request.data);
    }
    getUserAccountOsBySearch(search) {
      let url = `/userAccounts/os/namesearch`
      return axios.get(url,{
          params:{
            search
          }
      }).then(request => request.data);
    }
    getUserAccountRiderBySearch(search) {
      let url = `/userAccounts/rider/namesearch`
      return axios.get(url,{
          params:{
            search
          }
      }).then(request => request.data);
    }
}

const service = new UserAccountService();
export default service;